import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import _ from 'lodash'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { _convertCMSFile } from '@/helper/manageParameter'

import { useLocomotiveScroll } from 'react-locomotive-scroll'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import {
   BtnControlHeroAudio,
   BtnLinkPrimaryArrow,
} from '@/component/general/Button'
import { _animate_header_page } from '@/helper/gsap-crack/gsap-main-crack'
import HeroVideo from '../general/HeroVideo'
import { isMobile } from '@/helper/checkDeviceMedia'

gsap.registerPlugin(ScrollTrigger)

const HeadPage = ({
   content = {},
   isCTA = true,
   isVideo = false,
   extraClassDescription = '',
}) => {
   const [isContent, setIsContent] = useState(false)
   const [isMuted, setIsMuted] = useState(false)
   const { scroll } = useLocomotiveScroll()

   const { ref, inView } = useInView()

   const _handlePlayAudio = () => setIsMuted((current) => !current)

   // useEffect(() => {
   //     const intro = gsap.timeline()
   //
   //     const title = '.an-hero-title'
   //     const des = '.an-hero-desc'
   //     const cta = '.an-hero-cta'
   //     const banner = '.banner'
   //
   //     intro.to(
   //         title,
   //         {
   //             duration: 0.9,
   //             x: 1000,
   //             stagger: 0.5,
   //             opacity: 0,
   //         },
   //         'intro'
   //     )
   //
   //     content.description
   //         ? intro.to(
   //               des,
   //               {
   //                   duration: 0.9,
   //                   x: 800,
   //                   stagger: 0.5,
   //                   opacity: 0,
   //               },
   //               'intro=-.5'
   //           )
   //         : null
   //
   //     isCTA
   //         ? intro.to(
   //               cta,
   //               {
   //                   duration: 0.7,
   //                   x: 900,
   //                   stagger: 0.5,
   //                   opacity: 0,
   //               },
   //               'intro=-.6'
   //           )
   //         : null
   //
   //     intro.to(
   //         banner,
   //         {
   //             duration: 0.4,
   //             stagger: 0.5,
   //             opacity: 0,
   //         },
   //         'intro=-1'
   //     )
   //
   //     ScrollTrigger.create({
   //         animation: intro,
   //         scrub: 1.2,
   //         start: 'top',
   //         end: '140%',
   //     })
   //
   //     const scrollEl = document.querySelector('[data-scroll-container]')
   //     ScrollTrigger.scrollerProxy(scrollEl, {
   //         scrollTop(value) {
   //             if (scroll) {
   //                 return arguments.length
   //                     ? scroll.scrollTo(value, 0, 0)
   //                     : scroll.scroll.instance.scroll.y
   //             }
   //             return null
   //         },
   //         scrollLeft(value) {
   //             if (scroll) {
   //                 return arguments.length
   //                     ? scroll.scrollTo(value, 0, 0)
   //                     : scroll.scroll.instance.scroll.x
   //             }
   //             return null
   //         },
   //     })
   //
   //     const lsUpdate = () => {
   //         if (scroll) {
   //             scroll.update()
   //         }
   //     }
   //
   //     ScrollTrigger.addEventListener('refresh', lsUpdate)
   //     ScrollTrigger.refresh()
   //
   //     return () => {
   //         if (scroll) {
   //             ScrollTrigger.removeEventListener('refresh', lsUpdate)
   //             // scroll.destroy()
   //             // locoScroll = null;
   //             // console.log('Kill', scroll)
   //         }
   //     }
   // }, [])

   // useEffect(() => {
   //     if (scroll) {
   //         // const element = scroll?.el
   //         //
   //         // ScrollTrigger.scrollerProxy(element, {
   //         //     scrollTop(value) {
   //         //         if (element) {
   //         //             return arguments.length
   //         //                 ? element.scrollTo(value, 0, 0)
   //         //                 : element.scroll.instance.scroll.y
   //         //         }
   //         //         return null
   //         //     },
   //         //     scrollLeft(value) {
   //         //         if (element) {
   //         //             return arguments.length
   //         //                 ? scroll.scrollTo(value, 0, 0)
   //         //                 : scroll.scroll.instance.scroll.x
   //         //         }
   //         //         return null
   //         //     },
   //         // })
   //         //
   //         // const lsUpdate = () => {
   //         //     if (scroll) {
   //         //         scroll.update()
   //         //     }
   //         // }
   //         //
   //         // ScrollTrigger.addEventListener('refresh', lsUpdate)
   //         // ScrollTrigger.refresh()
   //
   //         const element = scroll?.el
   //         scroll.on('scroll', ScrollTrigger.update)
   //
   //         ScrollTrigger.scrollerProxy(element, {
   //             scrollTop(value) {
   //                 return arguments.length
   //                     ? scroll.scrollTo(value, {
   //                           duration: 0,
   //                           disableLerp: true,
   //                       })
   //                     : scroll.scroll.instance.scroll.y
   //             },
   //             getBoundingClientRect() {
   //                 return {
   //                     top: 0,
   //                     left: 0,
   //                     width: window.innerWidth,
   //                     height: window.innerHeight,
   //                 }
   //             },
   //             pinType: element.style.transform ? 'transform' : 'fixed',
   //         })
   //         ScrollTrigger.addEventListener('refresh', () => scroll?.update())
   //
   //         ScrollTrigger.refresh()
   //     }
   // }, [scroll])

   // useEffect(() => {
   //     const split = new SplitText('#header-text', {
   //         type: 'lines, words',
   //         linesClass: 'line',
   //         wordsClass: 'word',
   //     })
   //     new SplitText('#header-text', { type: 'words,chars' })
   //     gsap.to(split.lines, {
   //         duration: 1,
   //         y: 0,
   //         opacity: 1,
   //         stagger: 0.1,
   //         ease: 'power2',
   //     })
   // }, [])

   useEffect(() => {
      if (typeof window !== 'undefined') {
         setIsMuted(true)
      }

      // muted video
      // when not in view
      if (!inView) {
         setIsMuted(true)
      }
      _animate_header_page('.gsap-image-slide', '.gsap-text-slide')
   }, [inView])

   return (
      <section
         className="section-head-of-page bg-blackP bg-whiteP bg-dark-300"
         data-scroll-section>
         {isVideo &&
         !_.isEmpty(
            content.thumbnail_video ||
               (isVideo && !_.isEmpty(content.mobile_thumbnail_video))
         ) ? (
            <>
               {!_.isEmpty(content) &&
               !_.isEmpty(content.thumbnail_video) &&
               !isMobile() ? (
                  <HeroVideo
                     ref={ref}
                     url={_convertCMSFile(content.thumbnail_video?.url)}
                     isMuted={isMuted}
                     _handlePlayAudio={_handlePlayAudio}
                     extraClass={
                        !_.isEmpty(content.mobile_thumbnail_video) &&
                        !_.isEmpty(content.mobile_thumbnail_video?.url)
                           ? 'd-none d-md-block'
                           : 'd-block'
                     }
                  />
               ) : null}

               {!_.isEmpty(content) &&
               !_.isEmpty(content.mobile_thumbnail_video?.url) &&
               isMobile() ? (
                  <HeroVideo
                     ref={ref}
                     url={_convertCMSFile(
                        content.mobile_thumbnail_video?.url || ''
                     )}
                     isMuted={isMuted}
                     _handlePlayAudio={_handlePlayAudio}
                     extraClass="d-block d-md-none"
                  />
               ) : null}
            </>
         ) : (
            <>
               {!_.isEmpty(content) && !_.isEmpty(content.thumbnail) ? (
                  <img
                     src={_convertCMSFile(content.thumbnail.url || '')}
                     alt={
                        content.mobile_thumbnail.alternativeText ||
                        'GlobalXtreme Internet Service Provider'
                     }
                     className={
                        'banner gsap-image-slide ' +
                        (!_.isEmpty(content.mobile_thumbnail) &&
                        !_.isEmpty(content.mobile_thumbnail.url)
                           ? 'd-none d-md-block'
                           : 'd-block')
                     }
                     data-scroll
                     data-scroll-speed="-4"
                     width="100%"
                     height="100%"
                     loading="lazy"
                     fetchpriority="high"
                  />
               ) : null}

               {!_.isEmpty(content.mobile_thumbnail) &&
               !_.isEmpty(content.mobile_thumbnail.url) ? (
                  <img
                     src={_convertCMSFile(content.mobile_thumbnail.url || '')}
                     alt={
                        content.mobile_thumbnail.alternativeText ||
                        'GlobalXtreme Internet Service Provider'
                     }
                     className="d-block d-md-none banner-mobile"
                     data-scroll
                     data-scroll-speed="-4"
                     width="100%"
                     height="100%"
                     loading="lazy"
                     fetchpriority="high"
                  />
               ) : null}

               <div className="img-linear-background" />
            </>
         )}

         <div className="container">
            <div className="row">
               <div className="col-12 col-lg-10 position-static">
                  <div
                     className={
                        'hero-text-wrapper ' +
                        (!content.link && !isVideo
                           ? 'hero-text-wrapper-no-cta'
                           : '')
                     }>
                     <h1
                        id="header-text"
                        className={
                           'hero-text mt-4 mb-3 py-md-4 an-hero-title gsap-on-splitText gsap-text-slide ' +
                           (!content.title ? 'd-none' : '')
                        }
                        data-scroll
                        data-scroll-speed="-1.2">
                        {content.title ||
                           'GlobalXtreme Internet Service Provider'}
                     </h1>

                     {content.description ? (
                        <div className="gsap-text-slide hero-desc mb-0">
                           <div className={extraClassDescription}>
                              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                 {content.description}
                              </ReactMarkdown>
                           </div>
                        </div>
                     ) : null}

                     {content.link ? (
                        <div className="row">
                           <div className="col-md-6">
                              <div className="mt-5 gsap-text-slide">
                                 <BtnLinkPrimaryArrow
                                    name={content.linkName}
                                    href={content.link}
                                    extraClass="fw-500 mobile-fs-14"
                                 />
                              </div>
                           </div>
                        </div>
                     ) : null}
                  </div>
               </div>
            </div>

            {isVideo && !_.isEmpty(content.thumbnail_video) ? (
               <div className="row">
                  <div className="col-12">
                     <BtnControlHeroAudio
                        isMuted={isMuted}
                        _handlePlayAudio={_handlePlayAudio}
                        extraClass="d-block d-lg-none"
                     />
                  </div>
               </div>
            ) : null}
         </div>
      </section>
   )
}

export default HeadPage
